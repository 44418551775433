html {
  scroll-behavior: smooth;
}

body {
  margin: 0
}

a {
  text-decoration: none;
}

h1 {
  font-family: Staatliches;
  font-size: 80px;
}

#category {
  font-family: Staatliches;
  font-weight: 500;
}

#category h1 {
  font-size: 80px;
}

#title {
  letter-spacing: 0.4px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.13636;
}

#banner {
  max-height: 500px;
  margin-top: -64px;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background: #fff !important;
  font-family: Staatliches;
}

img:hover {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

em {
  color: '#FFF',
}

pre {
  display:none
}